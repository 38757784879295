<template>
    <span v-if="template.priceSheetsEnabled">
        <span v-if="configPricing.total">
            &euro; {{configPricing.total.toLocaleString('nl', { minimumFractionDigits: 2 })}}
        </span>
        <span v-else>
            &euro; {{'0.00'.toLocaleString('nl', { minimumFractionDigits: 2 })}}
        </span>
    </span>
    <span v-else>
        &euro; {{calculateTotal().toLocaleString('nl', { minimumFractionDigits: 2 })}}
    </span>
</template>

<script>

export default {
    computed: {
        template() {
            return this.$store.state.template
        },
        configs() {
            return this.$store.state.configs
        },
        configPricing() {
            return this.$store.state.configPricing
        }
    },
    methods: {
        calculateTotal() {
            let total = 0
            for (let i in this.configs) {
                for (let x in this.configs[i]) {
                    const cField = this.configs[i][x]
                    const tField = this.template.fields.find(f => f._id === cField._id)
                    if (tField.type === 'select') {
                        const option = tField.options.find(o => o._id === cField.optionId)
                        if (option && option.price) { total += parseFloat(option.price) }
                    }
                    else if (tField.type === 'check' && cField.value) {
                        if (tField.price) { total += parseFloat(tField.price) }
                    }
                }
            }
            return total
        }
    }
}

</script>