<template src="./Header.html"></template>
<style src="./Header.scss" lang="scss" scoped></style>

<script>

import { templateMixin } from '../../mixins/template'
export default {
    mixins: [ templateMixin ],
    methods: {
        groups() {
            return this.template.groups
        }
    }
}

</script>