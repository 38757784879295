import Summary from '../summary'

export default {
    components: { Summary },
    computed: {
        template() {
            return this.$store.state.template
        },
        configs() {
            return this.$store.state.configs
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    }
}