import Vue from 'vue'
import { ITemplate } from '../models/template'
import { IConfig } from '../store'
import { validateNumberInputField } from '../utils/field'

const templateMixin = Vue.extend({
    computed: {
        template(): ITemplate {
            return this.$store.state.template
        },
        configs(): IConfig[] {
            return this.$store.state.configs
        },
        config(): IConfig {
            return this.$store.state.configs[this.$store.state.activeConfig]
        },
        activeGroup() {
            return this.$store.state.activeGroup
        }
    },
    created() {
        window.addEventListener('message', e => {
            if (e.data?.route == 'nextGroup') {
                this.nextGroup()
            }
        })
    },
    methods: {
        validateInputFields() {
            const nrInputs = this.template.fields
                .filter(f => f.type == 'input' && f.inputType == 'number')
            const nrInputIds = nrInputs.map(b => b._id)
            const inputs = this.config.filter(a => nrInputIds.includes(a._id))
            const fieldWarnings: any[] = inputs
                .map(input => {
                    try { validateNumberInputField(this.template, input); return null }
                    catch(e) { return e }
                })
                .filter(w => w != null)
            if (fieldWarnings.length > 0) {
                this.$store.commit('fieldWarnings', fieldWarnings)
                return false
            }
            return true
        },
        validateFields() {
            return this.validateInputFields()
        },
        groupIndex(groupId: string): number {
            if (this.template && this.template.groups)
                return this.template.groups.findIndex(x => x._id == groupId)
            return 0
        },
        setGroup(groupId: string) {
            const currentIndex = this.groupIndex(this.activeGroup)
            const nextIndex = this.groupIndex(groupId)
            if (nextIndex > currentIndex) {
                if (!this.validateFields()) return
            }
            this.$store.commit('clearFieldWarnings')
            this.$store.commit('activeGroup', groupId)
        },
        previousGroup() {
            const index = this.groupIndex(this.activeGroup)
            if (index != -1 && this.template.groups[index - 1]) {
                this.setGroup(this.template.groups[index - 1]._id)
            }
        },
        nextGroup() {
            const index = this.groupIndex(this.activeGroup)
            if (index != -1 && this.template.groups[index + 1]) {
                this.setGroup(this.template.groups[index + 1]._id)
            }
            else if (this.isLastGroup()) {
                window.parent.postMessage({
                    route: 'complete',
                    data: {
                        config: this.prettify()
                    }
                }, '*')
            }
        },
        isFirstGroup() {
            if (this.template && this.template.groups)
                return this.groupIndex(this.activeGroup) == 0
        },
        isLastGroup() {
            if (this.template && this.template.groups)
                return this.groupIndex(this.activeGroup) + 1 == this.template.groups.length
        },
        prettify() {
            return this.configs.map(config => config.map(field => {
                const data = this.template.fields.find(f => f._id == field._id)
                if (data) {
                    if (data.type == 'select') {
                        return {
                            _id: data._id,
                            name: data.name,
                            title: data.title,
                            type: data.type,
                            option: data.options.find(o => o._id == field.optionId)
                        }
                    } else {
                        return {
                            _id: data._id,
                            name: data.name,
                            title: data.title,
                            type: data.type,
                            value: field.value
                        }
                    }
                }
            }))
        }
    }
})

export { templateMixin }