function Api() {

    const url = 'https://api.nebux.nl/v1'
    //let token = localStorage.getItem('token')

    function correctRoute(route) {
        if (!route.startsWith('/')) { route = '/' + route }
        return url + route
    }

    function fixHeaders(headers = {}) {
        //if (token) headers['Authorization'] = `Bearer ${token}`
        return headers
    }

    function request(method, route, headers = {}, body = null) {
        return new Promise((resolve, reject) => {
            let data = {}
            data.headers = fixHeaders(headers)
            data.method = method
            if (body) data.body = JSON.stringify(body)
            fetch(correctRoute(route), data)
                .then(async res => {
                    let message = {}
                    if (res.ok) {
                        try { message = await res.json() }
                        catch(e) { message = {} }
                        resolve(message)
                    }
                    else reject({ message: res.statusText, status: res.status })
                })
        })
    }
    
    async function get(route) {
        return await request('GET', route, { 'Accept': 'application/json' })
    }

    async function post(route, body) {
        const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        return await request('POST', route, headers, body)
    }

    return { get, post }

}

export default Api()