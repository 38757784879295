



































import { templateMixin } from '../../mixins/template'

export default {
    mixins: [ templateMixin ]
}

