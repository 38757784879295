import Spinner from '../spinner'

export default {
    components: { Spinner },
    computed: {
        template() {
            return this.$store.state.template
        },
        config() {
            return this.$store.state.configs[this.$store.state.activeConfig]
        },
        activeGroup() {
            return this.$store.state.activeGroup
        }
    },
    watch: {
        config(newConfig) {
            this.init(newConfig)
        },
        activeGroup() {
            this.init(this.config)
        }
    },
    mounted() {
        this.init(this.config)
    },
    data() {
        return {
            visuals: [],
            loadedVisuals: []
        }
    },
    methods: {
        init(newConfig) { 
            let visuals = []
            for (let i in newConfig) {
                const field = this.template.fields.find(f => f._id === newConfig[i]._id)
                if (field && this.linkedWithActiveGroup(field.groupId)) {
                    if (field.type === 'check' && field.visual) {
                        if (newConfig[i].value == field.showVisualIf || newConfig[i].value && field.showVisualIf === undefined) {
                            visuals.push(field.visual)
                        }
                    }
                    else if (field.type === 'select' && field.options) {
                        const option = field.options.find(x => x._id === newConfig[i].optionId)
                        if (option && option.visual) {
                            visuals.push(option.visual)
                        }
                    }
                    else if (field.visual) {
                        visuals.push(field.visual)
                    }
                }
            }
            this.visuals = visuals
        },
        linkedWithActiveGroup(groupId) {
            if (!groupId || groupId === this.activeGroup) return true
            const group = this.template.groups.find(g => g._id === this.activeGroup)
            return group && group.linkedGroups && group.linkedGroups.includes(groupId)
        },
        onLoadVisual(visual) {
            if (this.loadedVisuals.indexOf(visual._id) === -1)
                this.loadedVisuals.push(visual._id)
        },
        loading() {
            const visualIds = this.visuals.map(visual => visual._id)
            const unloaded = visualIds.filter(visualId => !this.loadedVisuals.includes(visualId))
            return unloaded.length > 0
        },
    }
}
