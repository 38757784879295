<template>
    <div id="app">
        <x-header></x-header>
        <div v-if="template && template._id" style="display:flex;">
            <x-visuals v-if="showVisuals()"></x-visuals>
            <x-fields></x-fields>
            <bottom-bar :totals="totals"></bottom-bar>
        </div>
    </div>
</template>

<script>

import XHeader from './components/header'
import XVisuals from './components/visuals'
import XFields from './components/fields'
import BottomBar from './components/BottomBar'
import Api from './api'

export default {
    name: 'app',
    components: { XHeader, XVisuals, XFields, BottomBar },
    computed: {
        template() {
            return this.$store.state.template
        },
        activeGroup() {
            return this.$store.state.activeGroup
        },
        config() {
            return this.$store.state.configs[this.$store.state.activeConfig]
        }
    },
    watch: {
        config(config) {
            if (this.template._id) {
                if (this.template.priceSheetsEnabled) {
                    Api.post('/pricing', { template: this.template._id, config })
                        .then(pricing => {
                            if (pricing) this.$store.commit('configPricing', pricing)
                        })
                        .catch(() => {
                            // console.error(err)
                        })
                }
            }
        }
    },
    data() {
        return {
            selectedConfig: 0,
            totals: [],
            loading: true
        }
    },
    created() {
        if (this.$route.query.tpl)
            this.getTemplate(this.$route.query.tpl)
        if (this.$route.query.lang)
            this.$store.commit('language', this.$route.query.lang)
    },
    methods: {
        getTemplate(_id) {
            Api.get(`/template/${_id}`)
                .then(template => {
                    // Remove Radio and Select fields without options
                    template.fields = template.fields.filter(x => x.type !== 'select' || (x.options && x.options.length))
                    // Take groups that are in use only
                    const usedGroups = template.fields.map(x => x.groupId)
                    template.groups = template.groups.filter(x => usedGroups.includes(x._id))
                    // Take virtual group for complete form
                    template.groups.push({ _id: 'complete', name: 'Complete' })
                    // Commit to store
                    this.$store.commit('template', template)
                    // Set active group
                    if (template.groups && template.groups.length)
                        this.$store.commit('activeGroup', template.groups[0]._id)
                })
        },
        showVisuals() {
            const group = this.template.groups.find(group => group._id === this.activeGroup)
            return (
                this.activeGroup !== 'complete' &&
                (group && group.showVisuals !== false)
            )
        },
        handleLoadingChange: function(loading) {
            this.loading = loading
        },
        handleClickBack: function() {
            const index = this.navigation.links.findIndex(x => x.id === this.navigation.active) - 1
            if (index !== -1 && this.navigation.links[index]) {
                this.navigationSetActive(this.navigation.links[index].id)
            }      
        },
        handleClickNext: function() {
            const index = this.navigation.links.findIndex(x => x.id === this.navigation.active) + 1
            if (index !== -1 && this.navigation.links[index]) {
                this.navigationSetActive(this.navigation.links[index].id)
            }
        }
    }
}

</script>

<style>
* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-font-smoothing: antialiased;
}
html, body {
  margin: 0;
  overflow: hidden;
  padding: 0;
}
#app {
    /*background-color: rgb(241, 241, 241);*/
    background-color: #f8fafb;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100vh;
}
select {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(221, 221, 221);
    border-radius: 5px;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-size: 1em;
    font-weight: 400;
    height: 40px;
    padding: 0 8px;
    width: 100%;
}
input {
    border: 1px solid rgb(221, 221, 221);
    border-radius: 5px;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-size: 1em;
    height: 40px;
    padding: 0 10px;
    width: 100%;
}
/* Headings */
h1 {
    font-weight: 500;
    font-size: 1.85em;
}
h2 {
    font-weight: 500;
    font-size: 1.85em;
}
h4 {
    color: #68758a;
    font-weight: 400;
}
/* Buttons */
button {
    background-color: #f0f0f0;
    border: 0;
    border-radius: 24px;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-size: 0.8125em;
    font-weight: 600;
    height: 48px;
    letter-spacing: 1.5px;
    outline: 0;
    padding: 0 64px;
    text-transform: uppercase;
}
button:hover {
    background-color: #f7f7f7;
}
button.hl {
    background-color: #007aff;
    color: #ffffff;
}
button.hl:hover {
    background-color: #288efd;
}
/* Pages */
.page-container {
    height: calc(100vh - 160px);
    overflow-y: auto;
    position: relative;
}
/* Columns */
.row {
    display: flex;
    margin-left: -8px;
    width: calc(100% + 16px);
}
.row .col {
    flex-grow: 1;
    padding: 0 8px;
}
@media only screen and (max-width: 600px) {
    .page-container {
        height: calc(100vh - 80px);
    }
    .only-desktop {
        display: none;
    }
}
@media only screen and (min-width: 600px) {
    .only-mobile {
        display: none;
    }
}
</style>
