<template>
    <div class="container">
        <div class="unassigned">
            <div v-if="config.length > 1" class="config-selector">
                <!--<Select
                    :options="configSelectOptions"
                    :value="selectedConfig"
                    @change="selectConfig" />-->
            </div>
        </div>
        <div class="total">
            <PriceTotal></PriceTotal>
        </div>
        <div class="only-desktop">
            <button v-if="!isFirstGroup()" @click="previousGroup" style="margin-right:10px;">Back</button>
            <button @click="nextGroup" class="hl">{{ isLastGroup() ? 'Complete' : 'Next' }}</button>
        </div>
        <div class="only-mobile">
            <button v-if="!isFirstGroup()" @click="previousGroup" style="margin-right:10px;">&lt;</button>
            <button @click="nextGroup" class="hl">&gt;</button>
        </div>
    </div>
</template>

<script>

import PriceTotal from './price-total'
import { templateMixin } from '../mixins/template'

export default {
    mixins: [ templateMixin ],
    props: {
        navigation: Object,
        //selectedConfig: Number,
        totals: Array
    },
    components: {
        PriceTotal
    },
    computed: {
        configPricing() {
            return this.$store.state.configPricing
        }
    },
    data() {
        return {
            configSelectOptions: []
        }    
    },
    watch: {
        config: {
            immediate: true, 
            handler(val) {
                this.configSelectOptions = []
                for (let id in val) {
                    const idint = parseInt(id)
                    this.configSelectOptions.push({
                        name: `Configuratie ${idint + 1}`,
                        value: idint
                    })
                }
            }
        }
    }
}
</script>

<style scoped>
.container {
    align-items: center;
    background-color: #333944;
    bottom: 0;
    display: flex;
    height: 80px;
    justify-content: center;
    padding: 0 1.25rem;
    position: fixed;
    width: 100%;
}
.unassigned {
    flex: 1;
}
.config-selector {
    max-width: 300px;
}
.total {
    color: #ffffff;
    font-size: 22px;
    font-weight: 700;
    margin-right: 32px;
}
.only-mobile button {
    padding: 0 32px;
}
</style>
