import Vue from 'vue'
import Vuex, { GetterTree, MutationTree, ActionTree } from 'vuex'
import { ITemplate, ITemplateField } from '../models/template'

Vue.use(Vuex)

export interface IConfigField {
    _id: string
    value?: string | number
    optionId?: string
}

export type IConfig = IConfigField[]

export interface IFieldWarning {
    _id: ITemplateField['_id']
    text: string
}

class State {
    template?: ITemplate = undefined
    language: 'en' = 'en'
    activeGroup?: number = 0
    configs: IConfig[] = [[]]
    configPricing: any = 0
    activeConfig: number = 0
    fieldWarnings: IFieldWarning[] = []
}

const getters = <GetterTree<State, any>>{

}

const mutations = <MutationTree<State>>{
    template(state, template) {
        state.template = template
    },
    language(state, language) {
        state.language = language  
    },
    activeGroup(state, groupId) {
        state.activeGroup = groupId
    },
    config(state, config) {
        state.configs.splice(state.activeConfig, 1, config)
    },
    configPricing(state, pricing) {
        state.configPricing = pricing
    },
    unsetConfig(state, fieldId) {
        const newConfig: any = state.configs[state.activeConfig]
        const index = newConfig.findIndex((f: any) => f._id == fieldId)
        if (index != -1) {
            state.configs.splice(state.activeConfig, 1, newConfig.filter((_: any, idx: number) => idx != index))
        }
    },
    fieldWarning(state, warning) {
        state.fieldWarnings = [
            ...state.fieldWarnings.filter(w => w._id != warning._id),
            warning
        ]
    },
    fieldWarnings(state, warnings) {
        state.fieldWarnings = [
            ...state.fieldWarnings,
            ...warnings
        ]
    },
    clearFieldWarning(state, fieldId) {
        state.fieldWarnings = state.fieldWarnings.filter(w => w._id != fieldId)
    },
    clearFieldWarnings(state) {
        state.fieldWarnings = []
    }
}

const actions = <ActionTree<State, any>>{

}

export default new Vuex.Store<State>({
    state: new State(),
    mutations,
    actions
})