import { ITemplate, ITemplateField } from '../models/template'
import { IConfig, IConfigField } from '../store'

export function shouldShowField(config: IConfig, field: ITemplateField) {
    let show = true
    if (!field.conditions) return show
    // hide conditionally
    if (field.conditions.hideIf && field.conditions.hideIf.length) {
        for (const hideIf of field.conditions.hideIf) { // or loop
            for (const con of hideIf) { // and loop
                const current = config.find(f => f._id === con.fieldId)
                if (current) {
                    if (con.optionId) { // select
                        if (con.not && con.optionId !== current.optionId) show = false
                        else if (!con.not && con.optionId === current.optionId) show = false
                        else {
                            show = true
                            break // look no further, it's a no match
                        }
                    }
                    else if (con.value) { // check / input
                        if (con.not && con.value != current.value) show = false
                        else if (!con.not && con.value == current.value) show = false
                        else {
                            show = true
                            break // look no further, it's a no match
                        }
                    }
                } else {
                    show = true
                    break
                }
            }
        }
    }
    // show conditionally (leading)
    if (field.conditions.showIf && field.conditions.showIf.length) {
        show = false // always start with `hide` if showIf conditions are present
        for (const showIf of field.conditions.showIf) { // or loop
            for (const con of showIf) { // and loop
                const current = config.find(f => f._id === con.fieldId)
                if (current) {
                    if (con.optionId) {
                        if (con.not && con.optionId !== current.optionId) show = true
                        else if (!con.not && con.optionId === current.optionId) show = true
                        else {
                            show = false
                            break // look no further, it's a no match
                        }
                    }
                    else if (con.value) {
                        if (con.not && con.value != current.value) show = true
                        else if (!con.not && con.value == current.value) show = true
                        else {
                            show = false
                            break // look no further, it's a no match
                        }
                    }
                } else {
                    show = false
                    break
                }
            }
            if (show) return show
        }
    }
    return show
}

export function validateNumberInputField(template: ITemplate, input: IConfigField) {
    const field = template.fields.find(field => field._id == input._id)
    if (input.value != undefined) {
        if (field && field.inputNumberMaxValue && parseInt(''+input.value) > field.inputNumberMaxValue) {
            throw {
                _id: field._id,
                text: `Value must be ${field.inputNumberMaxValue} or lower`
            }
        }
        else if (field && field.inputNumberMinValue && parseInt(''+input.value) < field.inputNumberMinValue) {
            throw {
                _id: field._id,
                text: `Value must be at least ${field.inputNumberMinValue}`
            }
        }
    }
    return true
}