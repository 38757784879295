<template>
    <div class="fields">
        <div v-if="activeGroup == 'complete'">
            <complete></complete>
        </div>
        <div v-else-if="ready">
            <div
                v-for="field in visibleFields"
                :key="`field${field._id}`"
                class="field"
                :class="{ visible: field.groupId == activeGroup }">
                <component
                    :is="component(field)"
                    :field="field"
                    :warning="warning(field._id)"
                    @change="handleFieldChange(field._id, $event)"></component>
                <br>
            </div>
        </div>
    </div>
</template>

<script>

import {
    shouldShowField,
    validateNumberInputField
} from '../../utils/field'
const FieldCheck = () => import('./check')
const FieldInput = () => import('./input')
const FieldSelect = () => import('./select')

import Complete from '../complete'

export default {
    components: { Complete },
    computed: {
        template() {
            return this.$store.state.template
        },
        config() {
            return this.$store.state.configs[this.$store.state.activeConfig]
        },
        activeGroup() {
            return this.$store.state.activeGroup
        },
        fieldWarnings() {
            return this.$store.state.fieldWarnings
        }
    },
    data() {
        return {
            ready: false,
            visibleFields: []
        }
    },
    created() {
        this.updateConfig()
    },
    methods: {
        component(field) {
            let component = []
            switch (field.type) {
                case 'check': component = FieldCheck; break
                case 'input': component = FieldInput; break
                case 'select': component = FieldSelect; break
            }
            return component
        },
        rebuildConfig(config, visible = null) {
            visible = visible ? visible : this.template.fields.filter(f => shouldShowField(config, f))
            const newConfig = []
            for (const field of visible) {
                const existing = config.find(f => f._id == field._id)
                if (existing) {
                    newConfig.push(existing)
                } else {
                    if (field.type == 'check' || field.type == 'input') {
                        newConfig.push({
                            _id: field._id,
                            value: field.defaultValue || undefined
                        })
                    }
                    else if (field.type == 'select') {
                        newConfig.push({
                            _id: field._id,
                            optionId: field.defaultOption || undefined
                        })
                    }
                }
            }
            const visibleFinal = this.template.fields.filter(f => shouldShowField(newConfig, f))
            if (visibleFinal.length > visible.length) {
                // Are there any more fields with conditions applicable to this new config?
                // Run again
                return this.rebuildConfig(newConfig, visibleFinal)
            } else {
                this.visibleFields = visible
                return newConfig
            }
        },
        updateConfig(changes = []) {
            if (this.template && this.template.fields) {
                const config = [ ...this.config ]
                // Apply changes (if provided)
                if (changes.length) {
                    for (const change of changes) {
                        const index = config.findIndex(f => f._id === change._id)
                        if (index !== -1) config[index] = change
                        else config.push(change)
                    }
                }
                this.$store.commit('config', this.rebuildConfig(config))
                this.ready = true
            }
        },
        handleFieldChange(fieldId, valueOrOptionId) {
            const field = this.template.fields.find(f => f._id === fieldId)
            // Validations
            try {
                validateNumberInputField(this.template, { _id: fieldId, value: valueOrOptionId })
                this.$store.commit('clearFieldWarning', fieldId)
            } catch(e) {
                this.$store.commit('fieldWarning', e)
            }
            if (field && (field.type == 'check' || field.type == 'input')) {
                this.updateConfig([{ _id: fieldId, value: valueOrOptionId }])
            }
            else if (field && field.type == 'select') {
                this.updateConfig([{ _id: fieldId, optionId: valueOrOptionId }])
            }
        },
        warning(fieldId) {
            return this.fieldWarnings.find(w => w._id == fieldId)
        }
    }
}

</script>

<style src="./Fields.scss" lang="scss" scoped></style>